import loadable from '@loadable/component';
import AuthLayout from 'layouts/auth-layout';
import GlobalLayout from 'layouts/global-layout';
import PrivateLayout from 'layouts/private-layout';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const page = elem => elem;

const IndexPage = loadable(() => import('pages/index'));
const SignInPage = loadable(() => import('pages/sign-in'));
const SignUpPage = loadable(() => import('pages/sign-up'));
const SignOutPage = loadable(() => import('pages/sign-out'));
const ConfirmPage = loadable(() => import('pages/confirm'));
const RecoverPasswordPage = loadable(() => import('pages/recover-password'));
const SignedInPage = loadable(() => import('pages/signed-in'));
const AccessDeniedPage = loadable(() => import('pages/access-denied'));
const NotFoundPage = loadable(() => import('pages/not-found'));

const PageRouter = () => {

    return (
        <Routes>
            <Route element={<GlobalLayout />}>
                <Route element={<PrivateLayout />}>
                    <Route index element={page(<IndexPage />)} />
                </Route>
                <Route element={<AuthLayout />}>
                    <Route path="/sign-in" element={page(<SignInPage />)} />
                    <Route path="/sign-up" element={page(<SignUpPage />)} />
                    <Route path="/confirm" element={page(<ConfirmPage />)} />
                    <Route path="/recover-password" element={page(<RecoverPasswordPage />)} />
                </Route>
                <Route path="/sign-out" element={page(<SignOutPage />)} />
                <Route path="/signed-in" element={page(<SignedInPage />)} />
                <Route path="/access-denied" element={page(<AccessDeniedPage />)} />
                <Route path="/not-found" element={page(<NotFoundPage />)} />
                <Route path="*" element={page(<NotFoundPage />)} />
            </Route>
        </Routes>
    )
}

export default PageRouter;
