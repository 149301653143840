import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import LoadingPage from 'pages/_loading';
import { useIntl } from 'react-intl';

export default function PrivateLayout() {
    const auth = useAuth();
    const location = useLocation();
    const intl = useIntl();

    // check if loading in progress
    const loading = auth.isLoading || auth.activeNavigator;

    React.useEffect(() => {
        if (!loading && (!auth.isAuthenticated || auth.error)) {
            auth.signinRedirect({ 
                state: `${location.pathname}${location.search}`,
                extraQueryParams: { lang: intl.locale } 
                
            }).catch((err) => err);
        }
    }, [location.pathname, location.search, loading, auth, intl.locale]);

    React.useEffect(() => {

        auth.clearStaleState();

        return auth.events.addUserSignedOut(() => {
            auth.removeUser();
        })
    }, [auth]);

    if (loading || !auth.isAuthenticated) {
        return <LoadingPage />;
    }

    return <Outlet />;
}
