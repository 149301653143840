import * as React from 'react';
import { createSearchParams, Outlet, useSearchParams } from 'react-router-dom';
import { actions as userActions } from 'redux/users/slice';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Col, Layout, Row } from 'antd';
import Loader from 'components/loader';
import { isEmpty } from 'dash';

export default function AuthLayout() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [progress, setProgress] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // the callback to capture context
    const contextCapture = React.useCallback(async (ret) => {

        // get the default login hint and language
        const currentLoginHint = searchParams.get('login_hint') || searchParams.get('loginHint') || '';
        const currentLang = intl.locale;

        setProgress(true);
        // try get context of sign-in
        const result = await dispatch(userActions.signinContext({
            input: {
                returnUrl: ret
            }
        }));

        setProgress(false);

        // nothing to do as error
        if (result.error) {
            return;
        }

        // get context
        const context = result?.payload || {};

        // the locale or default
        const lang = context?.lang || currentLang;
        const loginHint = context?.loginHint || currentLoginHint

        const localizedQuery = createSearchParams(searchParams);
        let modified = false;

        // do nothing as locale did not change
        if (lang !== currentLang) {
            localizedQuery.set('lang', lang);
            modified = true;
        }

        if (loginHint && loginHint !== currentLoginHint) {
            localizedQuery.set('login_hint', loginHint);
            modified = true;
        }

        if (modified) {
            setSearchParams(localizedQuery, { replace: true });
        }

    }, [dispatch, intl.locale, searchParams, setSearchParams]);

    const returnUrl = searchParams.get('return_url') || searchParams.get('returnUrl') || '';

    // make sure context is captured and localized
    React.useEffect(() => {
        if (isEmpty(returnUrl)) {
            return;
        }
        contextCapture(returnUrl)
    }, [contextCapture, returnUrl]);

    return <Layout>
        <Layout.Content style={{
            backgroundColor: '#f2f4f8',
            overflow: 'auto'
        }}>

            <Row style={{ height: '100vh' }}>
                <Col span={24} style={{ margin: 'auto' }}>
                    <Row align='middle'>
                        <Col xs={22} sm={15} md={10} lg={6} xl={6} xxl={6} 
                         style={{ margin: 'auto', display: 'flex', paddingBottom: '12px'}}>
                            <img src="/logo544x180.png" style={{maxWidth: '180px', margin: '0 auto'}} alt="Enlight Studies" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={22} sm={15} md={10} lg={6} xl={6} xxl={6}
                            style={{
                                margin: 'auto',
                                padding: 24,
                                backgroundColor: 'white',
                                borderRadius: 5,
                                boxShadow: '0 4px 38px 0 rgba(22,21,55,.06),0 0 21px 0 rgba(22,21,55,.03)'
                            }}>
                            {progress ? <Loader style={{ minHeight: 200 }}/> : <Outlet /> }
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Layout.Content>
    </Layout>;
}
