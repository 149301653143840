import { ConfigProvider } from "antd";
import locales from "locales";
import { useIntl } from "react-intl";

const ConnectedConfigProvider = props => {
    
    const intl = useIntl();

    const localeData = locales[intl.locale] || locales[intl.defaultLocale]

    return <ConfigProvider locale={localeData.antd} children={props.children} />
}

export default ConnectedConfigProvider;