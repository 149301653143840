import PageRouter from 'page-router';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'redux/store';
import ConnectedIntlProvider from 'providers/connected-intl-provider';
import ConnectedConfigProvider from 'providers/connected-config-provider';
import { oidcContextConfig } from 'auth';
import Helmet from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import ThemeProvider from 'theme/theme-provider';

const App = () => {

  return (
    <>
      <Helmet titleTemplate="%s | Enlight Studies" defer={false}>
        <title>Loading</title>
      </Helmet>

      <BrowserRouter>
        <AuthProvider {...oidcContextConfig}>
          <ReduxProvider store={store}>
            <ConnectedIntlProvider>
              <ConnectedConfigProvider>
                <ThemeProvider>
                  <PageRouter />
                </ThemeProvider>
              </ConnectedConfigProvider>
            </ConnectedIntlProvider>
          </ReduxProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  )
};


export default App;
