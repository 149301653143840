import Loader from "components/loader";
import Helmet from "react-helmet";
import { useIntl } from "react-intl";


const LoadingPage = () => {
    
    const intl = useIntl();

    return (
        <>
            <Helmet title={intl.formatMessage({id: "global.loading.title"})} />
            <Loader style={{minHeight: '100vh'}}/>
        </>
    )
}

export default LoadingPage;