import hyAm from './hy_AM.json';
import enUS from './en_US.json';
import antHyAM from 'antd/lib/locale/hy_AM';
import antEnUs from 'antd/lib/locale/en_US';

const locales = {
    "hy-AM": {
        codes: ["hy", "hy-AM"],
        display: "Հայերեն",
        messages: {...enUS, ...hyAm },
        antd: antHyAM
    },
    "hy": {
        codes: ["hy", "hy-AM"],
        display: "Հայերեն",
        messages: { ...enUS, ...hyAm },
        antd: antHyAM
    },
    "en-US": {
        codes: ["en", "en-US"],
        display: "English",
        messages: enUS,
        antd: antEnUs
    },
    "en": {
        codes: ["en", "en-US"],
        display: "English",
        messages: enUS,
        antd: antEnUs
    }
};

export const supportedLocales = Object.keys(locales);
export const defaultLocale = "en";

export const displayLocales = [
    {
        display: locales["hy"].display,
        codes: locales["hy"].codes
    },
    {
        display: locales["en"].display,
        codes: locales["en"].codes
    }
]
export default locales;