import { combineReducers } from 'redux'
import { reducer as settings } from './settings/slice'
import { reducer as users } from './users/slice'


const rootReducer = combineReducers({
  settings,
  users
})

export default rootReducer