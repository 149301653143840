import { createAsyncThunk } from "@reduxjs/toolkit";
import { connectClient } from "api"
import { rejectable } from "redux/utility";

export const signup = createAsyncThunk(
    'users/signup',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.signup(input)
        return response.data
    })
)

export const signinContext = createAsyncThunk(
    'users/signin-context',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.signinContext(input)
        return response.data
    })
)

export const signin = createAsyncThunk(
    'users/signin',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.signin(input)
        return response.data
    })
)

export const requestOtp = createAsyncThunk(
    'users/request-otp',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.requestOtp(input)
        return response.data
    })
)

export const requestConfirmation = createAsyncThunk(
    'users/request-confirmation-code',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.requestConfirmation(input)
        return response.data
    })
)

export const requestPasswordRecovery = createAsyncThunk(
    'users/request-password-recovery',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.requestPasswordRecovery(input)
        return response.data
    })
)

export const processConfirmation = createAsyncThunk(
    'users/confirm-account',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.processConfirmation(input)
        return response.data
    })
)

export const processPasswordRecovery = createAsyncThunk(
    'users/process-password-recovery',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.processPasswordRecovery(input)
        return response.data
    })
)
export const signout = createAsyncThunk(
    'users/signout',
    async ({ input }, thunkAPI) => await rejectable(thunkAPI, async () => {
        const response = await connectClient.signout(input)
        return response.data
    })
)

