
import { IntlProvider } from 'react-intl'
import locales, { defaultLocale } from 'locales'
import { useLocation } from 'react-router-dom'

const ConnectedIntlProvider = props => {

    const location = useLocation();
    const search = new URLSearchParams(location.search)
    const locale = search.get("lang") || defaultLocale;
    const localeData = locales[locale] || locales[defaultLocale]

    return <IntlProvider locale={ locale } defaultLocale={ defaultLocale } messages={ localeData.messages } children={props.children} />
}

export default ConnectedIntlProvider;