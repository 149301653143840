import { ConfigProvider } from "antd";

export default function ThemeProvider({children}) {

    return <ConfigProvider theme={{
        token: {
            fontFamily: '"Arial", sans-serif',
            colorPrimary: '#572D86',
            colorLink: '#572D86',
            colorLinkActive: '#572D86',
            colorLinkHover: 'rgb(87, 45, 134, .5)',
            colorBgBase: '#fff',
            colorSuccess: '#237804',
            colorWarning: '#ad8b00',
            colorError: '#a8071a' 
        }
    }}>
        {children}
    </ConfigProvider>
}